import React, {Component} from 'react';

import '../Body.css';
// import '../Resume.css';
import './resume.css';

// import ReactGA from "react-ga";

export class Resume_v2 extends Component {
    static displayName = Resume_v2.name;

    render() {
        return (
            <div className="page-wrap">
                <div className="pgContainer">
                    <div className="overview">
                        <img src="../img/cthulu.png" alt="Cthulu" id="pic"/>
                        <div id="contact-info" className="vcard">
                            <h1 className="fn">Robert J. DeWilde</h1>
                            <div className="social">
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/robertjdewilde"><img alt="" className="social" src={"img/icoLinkedIn.png"}/></a>
                                <a target="_blank" rel="noreferrer" href="https://twitter.com/RobertJDeWilde"><img alt="" className="social" src={"img/icoTwitter.png"}/></a>
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/robert.j.dewilde"><img alt="" className="social" src={"img/icoFacebook.png"}/></a>
                            </div>
                            <p>
                                Cell: <span className="tel">205-527-9244</span><br/>
                                Email: <a className="email" href="mailto:robert.j.dewilde@cyberneticinnovations.com">Robert.J.DeWilde@CyberneticInnovations.com</a>
                            </p>
                        </div>
                        <div id="objective">
                            <h3 className="fn">Full Stack - Senior Software Engineer</h3>
                            <p>
                                Exceptionally well-qualified senior professional with sophisticated technical skills and a passion for resolving complex problems and business challenges through innovation.
                            </p>
                            <p>
                                Excellent ability to work well with others on a team or individual basis. Completes tasks at a fast pace and has the ability to train, mentor and motivate individuals to grow to their professional potential.
                            </p>
                        </div>
                    </div>
                    <div className="left">
                        <dl>
                            <dt className="l">Experience</dt>
                            <dd className="l">
                                <div>
                                    <h2>Regions Financial Corporation<span>Birmingham, Alabama : Jun 2011 - Present</span></h2>
                                    <p id="jobtitle">Senior Software Engineer IV / DevOps</p>
                                    <p>
                                        <ul>
                                            <li>Responsible for the design, development, testing and support of multiple software applications and APIs.</li>
                                            <li>Created monitoring dashboards using Grafana, by using the Splunk API to gather application metrics and influxDB to store the time series data.</li>
                                            <li>Implemented CI/CD for application deployment from Development to Docker to OpenShift.</li>
                                            <li>Managed a team of  ETL/ELT application developers on the creation of the company's Operational Data Store. Teams were Co-Located in Nashville and Birmingham. This included ETL and analytics on varying data sources including flat files, mainframe data, and distributed systems using the Hadoop ecosystem.                                            </li>
                                            <li>Developed and supported AI solutions for:
                                                <ul>
                                                    <li>CIP - Customer Identification Program</li>
                                                    <li>RBU - Regions Business Universe</li>
                                                    <li>RBOR - Region Business Opportunity Repository</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div>
                                    <h2>Cybernetic Innovations<span>Alabaster, Alabama : Jan 1994 - Present</span></h2>
                                    <p id="jobtitle">Chief Software Architect</p>
                                    <ul>
                                        <li>Custom applications, Web Design for small businesses. Applications including invoice tracking, database management and point-of-sale integration to 3rd party software.</li>
                                        <li>Build, maintain and install computers for retail organizations.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2>First Tennessee Financial Corporation <span>Memphis, Tennessee : Jan 2011 - May 2011</span></h2>
                                    <p id="jobtitle">VP, Manager of Web Development</p>
                                    <p>
                                        Managed a team of 2 developers.<br/>
                                        Managed Web application development and Web technology solutions.
                                    </p>
                                    <ul>
                                        <li>Manage Web development projects.</li>
                                        <li>Prototype development of CMS options in SharePoint 2010.</li>
                                        <li>Develop project plans, specifications, application prototypes and information architecture.</li>
                                        <li>Conduct business process and requirements analysis.</li>
                                        <li>Conduct new business development as technical representative</li>
                                        <li>Contribute to user interface, design and development efforts</li>
                                        <li>Contribute to application and website testing efforts</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2>Regions Financial Corporation<span>Birmingham, Alabama : Oct 2005 - Dec 2010</span></h2>
                                    <p id="jobtitle">Senior Software Engineer IV-Team Lead</p>
                                    <p>
                                        Manage a team of 5 developers.<br/>
                                        Created and launched a Microsoft award-winning, multi-tiered .NET Content Management System platform that is currently used for both internal and external sites. Internal System provides content to over 36,000+ employees; External System provides content
                                        to over 1.2 million visitors each month.
                                    </p>
                                </div>
                                <div>
                                    <h2>Sigmaworx, Inc.<span>Davenport, Iowa : Sep 2002 - May 2005</span></h2>
                                    <p id="jobtitle">Senior Software Engineer III</p>
                                    <p>
                                        Project manager/Lead Developer for a software migration project to convert a DOS based Predictive Dialer to .NET platform.
                                    </p>
                                </div>
                                <div>
                                    <h2>AmSouth Bank<span>Birmingham, Alabama : Jan 2001 - Sep 2002</span></h2>
                                    <p id="jobtitle">Consumer Banking Officer - Treasury Management</p>
                                    <p>
                                        Lead web programmer for Treasury Management - Lockbox Division. Created online application that enables clients to view their account information of daily payments in real time. Client can view images of
                                        checks, coupons
                                        and transactions reports of their daily activity.
                                    </p>
                                    <ul>
                                        <li>Assisted customer service with client problems.</li>
                                        <li>Maintained data archiving system.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2>Del-Rene Co.<span>Lincoln, Nebraska : Jun 2000 - Dec 2000</span></h2>
                                    <p id="jobtitle">Vice-President of IT/Web Development</p>
                                    <p>
                                        Managed administration of Windows 2000 web servers, email systems, database administration. In charge of all web site designs and administration.
                                    </p>
                                    <ul>
                                        <li>Assist web clients with any problems, changes or updates to their web sites.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2>Electronic Technical Services<span>Davenport, Iowa : Jan 1991 - Jul 2000</span></h2>
                                    <p id="jobtitle">Director of Engineering</p>
                                    <p>
                                        Managed the following areas Design/Programming, Technical Support and System Operations.
                                    </p>
                                    <ul>
                                        <li>Design and implementation of data communication software.</li>
                                        <li>Customized software for end-user needs.</li>
                                        <li>Provided telephone support for software and hardware problems.</li>
                                        <li>Trained customers in operations of software.</li>
                                        <li>Installed system hardware and peripherals.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2>Black Hawk College<span>Moline, Illinois : Jan 2000 - Jul 2000</span></h2>
                                    <p id="jobtitle">Windows NT Instructor</p>
                                </div>
                            </dd>
                            <dd><br/></dd>
                            <dt className="l">Education</dt>
                            <dd className="l">
                                <div>
                                    <h2>Black Hawk Collage <span>Moline, Illinois - 1995-1999</span></h2>
                                    <p>Associate degree in Programming / Computer Maintenance</p>
                                </div>
                            </dd>
                        </dl>
                    </div>
                    <div className="right">
                        <dl>
                            <dt class="r">Skills</dt>
                            <dd class="r">
                                <div>Software Engineering</div>
                                <div>DevOps</div>
                                <div>Architecture</div>
                                <div>Web Development</div>
                                <div>Artificial Intelligence</div>
                                <div><br/></div>
                            </dd>
                            <dt class="r">Technologies</dt>
                            <dd class="r">
                                <div><p id="skill-title">DevOps:</p></div>
                                <div id="pad">Kubernetes</div>
                                <div id="pad">OpenShift</div>
                                <div id="pad">Bamboo</div>
                                <div id="pad">Harness</div>
                                <div id="pad">Grafana</div>
                                <div id="pad">Splunk</div>
                                <div id="pad">Postman</div>
                                <div><br/></div>
                                <div><p id="skill-title">Languages:</p></div>
                                <div id="pad">Python</div>
                                <div id="pad">C#, C++, C</div>
                                <div id="pad">Java</div>
                                <div id="pad">JavaScript</div>
                                <div><br/></div>
                                <div><p id="skill-title">Web:</p></div>
                                <div id="pad">.Net Core</div>
                                <div id="pad">Spring, Spring-Boot</div>
                                <div id="pad">Flask, Django</div>
                                <div><br/></div>
                                <div><p id="skill-title">Data: </p></div>
                                <div id="pad">SQL</div>
                                <div id="pad">Hadoop, Hive, Impala</div>
                                <div id="pad">MongoDB</div>
                                <div id="pad">influxDB</div>
                                <div><br/></div>
                                <div><p id="skill-title">Machine Learning:</p></div>
                                <div id="pad">Alteryx</div>
                            </dd>
                        </dl>

                        {/*<dl>*/}
                        {/*    <dt className="r">Skills</dt>*/}
                        {/*    <dd className="r">DevOps</dd>*/}
                        {/*    <dd className="r">Web Development</dd>*/}
                        {/*    <dd className="r">Software Engineering</dd>*/}
                        {/*    <dd className="r">Architecture</dd>*/}
                        {/*    <dd className="r">Big Data</dd>*/}
                        {/*    <dd className="clear"></dd>*/}
                        {/*</dl>*/}

                    </div>
                </div>
            </div>
        )
    }
}
