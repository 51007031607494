import React, {Component} from 'react';
import {Route} from 'react-router';
import {Layout} from './components/Layout';
import ReactGA from 'react-ga';

import './custom.css'
// import {Resume} from "./components/Resume";
import {Resume_v1} from "./components/Resume-v1/resume";
import {Resume_v2} from "./components/Resume-v2/resume";
import {NotFound} from "./components/404/NotFound";
export default class App extends Component {
    static displayName = App.name;
    setGA = () => {
        ReactGA.initialize('UA-5194748-1');  // Production
        // ReactGA.initialize('UA-48073150-1');    // Development
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    componentDidMount() {
        this.setGA();
    }

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Resume_v2}/>
                <Route exact path='/resume' component={Resume_v2}/>
                <Route exact path='/resume_1' component={Resume_v1}/>
                <Route exact path='/resume_2' component={Resume_v2}/>
                <Route component={NotFound}/>
            </Layout>
        );
    }
}
