import React, {Component} from 'react';
import '../Body.css';
import './resume.css';

export class Resume_v1 extends Component {
    static displayName = Resume_v1.name;

    render() {
        return (
            <div id="page-wrap">
                <img src="img/cthulu.png" alt="Cthulu" id="pic"/>
                <div id="contact-info" className="vcard">
                    <h1 className="fn">Robert J. DeWilde</h1>
                    <div className="social">
                        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/robertjdewilde"><img alt="" className="social" src={"img/icoLinkedIn.png"}/></a>
                        <a target="_blank" rel="noreferrer" href="https://twitter.com/RobertJDeWilde"><img alt="" className="social" src={"img/icoTwitter.png"}/></a>
                        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/robert.j.dewilde"><img alt="" className="social" src={"img/icoFacebook.png"}/></a>
                    </div>
                    <p>
                        Cell: <span className="tel">205-527-9244</span><br/>
                        Email: <a className="email" href="mailto:robert.j.dewilde@cyberneticinnovations.com">Robert.J.DeWilde@CyberneticInnovations.com</a>
                    </p>
                </div>
                <div id="objective">
                    <h3 className="fn">Software & Web Design / Network Administration</h3>
                    <p>
                        Exceptionally well-qualified senior professional with sophisticated technical skills and a passion for resolving complex problem or business challenges through innovation.
                    </p>
                    <p>
                        Excellent ability to work well with others on a team or individual basis. Completes tasks at a fast pace and has the ability to train, mentor and motivate individuals to grow to their professional potential.
                    </p>
                </div>
                <div className="clear">
                </div>
                <dl>
                    <dd className="clear"></dd>
                    <dt>Experience</dt>
                    <dd>
                        <h2>Regions Financial Corporation<span>Birmingham, AL : May 2011 - Present</span></h2>
                        <p>
                            Senior Software Engineer IV
                        </p>
                        
                    </dd>
                    <dd>
                        <h2>First Tennessee Financial Corporation <span>Memphis, TN : Jan 2011 - May 2011</span></h2>
                        <p>
                            VP, Manager of Web Development<br/>
                            Manage a team of 2 developers.<br/>
                            Manage Web application development and Web technology solutions.
                        </p>
                        <ul>
                            <li>Manage Web development projects.</li>
                            <li>Prototype development of CMS options in SharePoint 2010.</li>
                            <li>Develop project plans, specifications, application prototypes and information architecture.</li>
                            <li>Conduct business process and requirements analysis.</li>
                            <li>Conduct new business development as technical representative</li>
                            <li>Contribute to user interface, design and development efforts</li>
                            <li>Contribute to application and website testing efforts</li>
                        </ul>
                    </dd>
                    <dd>
                        <h2>Regions Financial Corporation<span>Birmingham, AL : Oct 2005 - Dec 2010</span></h2>
                        <p>
                            Senior Software Engineer IV-Team Lead<br/>
                            Manage a team of 5 developers.<br/>
                            Created and launched a Microsoft award-winning, multi-tiered .NET Content Management System platform that currently used for
                            both internal and external sites. Internal System provides content to over 36,000+ employees; External System provides content
                            to over 1.2 million visitors each month.
                        </p>
                        <ul>
                            <li>Optimized sites to have an average page load time from .3 to .5 seconds</li>
                            <li>Won 2009 Gomez Web Performance Award</li>
                        </ul>
                    </dd>
                    <dd>
                        <h2>Sigmaworx, Inc.<span>Davenport, IA : Sep 2002 - May 2005</span></h2>
                        <p>
                            Senior Software Engineer III<br/>
                            Project manager/Lead Developer for a software migration project to convert a DOS based Predictive Dialer to .NET platform.
                        </p>
                    </dd>
                    <dd>
                        <h2>AmSouth Bank<span>Birmingham, AL : Jan 2001 - Sep 2002</span></h2>
                        <p>
                            Consumer Banking Officer - Treasury Management<br/>
                            Lead web programmer for Treasury Management - Lockbox Division. Created online application that enables clients to view their account information of daily payments in real time. Client can view images of checks, coupons
                            and transactions reports of their daily activity.
                        </p>
                        <ul>
                            <li>Assisted customer service with client problems.</li>
                            <li>Maintained data archiving system.</li>
                        </ul>
                    </dd>
                    <dd>
                        <h2>Del-Rene Co.<span>Lincoln, Nebraska : Jun 2000 - Dec 2000</span></h2>
                        <p>
                            Vice-President of IT/Web Development<br/>
                            Managed administration of Windows 2000 web servers, email systems, database administration. In charge of all web site designs and administration.
                        </p>
                        <ul>
                            <li>Assist web clients with any problems, changes or updates to their web sites.</li>
                        </ul>
                    </dd>
                    <dd>
                        <h2>Electronic Technical Services<span>Davenport, Iowa : Jan 1991 - Jul 2000</span></h2>
                        <p>
                            Director of Engineering<br/>
                            Managed the following areas Design/Programming, Technical Support and System Operations.
                        </p>
                        <ul>
                            <li>Design and implementation of data communication software.</li>
                            <li>Customized software for end-user needs.</li>
                            <li>Provided telephone support for software and hardware problems.</li>
                            <li>Trained customers in operations of software.</li>
                            <li>Installed system hardware and peripherals.</li>
                        </ul>
                    </dd>
                    <dd>
                        <h2>Black Hawk College<span>Moline, Illinois : Jan 2000 -Jul 2000</span></h2>
                        <p>
                            Windows NT Instructor
                        </p>
                    </dd>
                    <dd>
                        <h2>Cybernetic Innovations<span>Alabaster, Alabama - Jan 1994-Present</span></h2>
                        <p>Owner</p>
                        <ul>
                            <li>Custom applications, Web Design for small businesses. Applications including invoice tracking, database management and point-of-sale integration to 3rd party software.</li>
                            <li>Build, maintain and install computers for retail organizations.</li>
                        </ul>
                    </dd>
                    <dd className="clear"></dd>
                    <dt>Education</dt>
                    <dd>
                        <h2>Black Hawk Collage <span>Moline, IL - 1995-1999</span></h2>
                        <p>Associate degree in Programming / Computer Maintenance</p>
                    </dd>
                    <dd className="clear"></dd>
                    <dt>Skills</dt>
                    <dd>
                        <h2>Operating Systems</h2>
                        <ul>
                            <li>Windows Server 2008, 2003, 2000</li>
                            <li>Windows-7, Windows-XP, Windows-2000, Windows NT4, Win98</li>
                        </ul>
                        <h2>Programming Languages</h2>
                        <ul>
                            <li>SharePoint 2010, .Net C#/VB/ASP 2003-2010, VB6, Delphi</li>
                            <li>TCP/IP, JavaScript, CSS, jQuery</li>
                        </ul>
                        <h2>Databases</h2>
                        <ul>
                            <li>SQL 2008, 2005, 2000</li>
                            <li>Sybase, MySql</li>
                        </ul>
                    </dd>
                    <dd className="clear"></dd>
                    <dt>Hobbies</dt>
                    <dd>Golf, Tennis and Bodybuilding</dd>
                    <dd className="clear"></dd>
                    <dt>References</dt>
                    <dd>Available on request</dd>
                    <dd className="clear"></dd>
                </dl>
                <div className="clear">
                </div>


            </div>
        );
    }
}
