import React, {Component} from 'react';
// import '../Body.css';
import './NotFound.css'

export class NotFound extends Component {
    static displayName = NotFound.name;

    render() {
        return (
            <div className="_404">
                {/*<p className="_404" id="error">E<span>r</span>ror</p>*/}
                
                {/*<p id="code">4<span>0</span><span>4</span></p>*/}
            </div>);
    }
}
